.main_slotset {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;

}

.top_secSlot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
}

.bottom_slot {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
}

.bottom_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.slot_title {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    color: #212529;
}

.slot_group {
    width: 100%;
    padding: 10px 0px;
}

.slot_input {
    width: 100%;
    height: 35px;
    outline: none;
    border-radius: 5px;
    border: none;
    background-color: whitesmoke;
    padding-left: 10px;
    margin-top: 10px;
}

.ant-modal-footer{
    display: none;
}

.slot_btn_sec{
    width: 100%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding-top: 20px;
}