:root {
    --primary-button-color: rgb(32, 126, 233);
    --primary-button-hover-color: #2865ac;
    --primary-hover-color: black;
    --danger: #dc3545;
}

.userCreate:hover {
    color: var(--primary-hover-color) !important;
}

.deleteButton {
    border-color: var(--danger) !important;
    background-color: #Fff;
    color: black;

}

.deleteButton:hover {
    background-color: var(--danger) !important;
    color: #fff !important;
}

.column {
    margin-top: 58px !important;
    border: 1px solid #00000020;
}

.activeUser {
    background-color: var(--primary) !important;
    color: #fff !important;
}

.activeUser:hover {
    color: var(--primary-hover-color) !important;
}

.letterProfile {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
    line-height: 40px;
}

.profileContainer {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: #938f8f;
}

.activeUser .letterProfile {
    color: black;
}

.activeUser .profileContainer {
    background: #fff;
}

.userHover:hover {
    color: var(--primary-hover-color) !important;
}

.hide {
    display: none !important;
}

.storeHide {
    display: none !important;
}

.insightYearParentDiv {
    display: flex !important;
    /* margin-top: 1px; */
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2px;
    padding-bottom: 25px;

}

.insightYear {
    display: flex !important;
    cursor: pointer;
    direction: rtl;
    width: 35px;


}


.insightYearActive {
    direction: rtl;
    width: 35px;
    color: #fd7e14 !important;
    /* background-color: #fd7e14 !important;
    color: #fff; */
    border-radius: 40%;

}

.overflow {
    overflow-y: scroll !important;
    overscroll-behavior: auto;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff;
    background-color: #dee2e6;
}

#style-3::-webkit-scrollbar {
    width: 3px !important;
    background-color: #dee2e6 !important;
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
}

.horizontalScrollbar {
    overflow-x: auto !important;
    overscroll-behavior: auto;
    background: #fff !important;
    width: 3px;

}

#style-horizontal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 20px #fff;
    background-color: #dee2e6;
}

#style-horizontal::-webkit-scrollbar {
    width: 3px !important;
    height: 5px !important;
    background-color: #dee2e6 !important;
}

#style-horizontal::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
}

/* #style-horizontal::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
    background-color: #dee2e6;
} */



.scrollbar {
    /* margin-left: 30px; */
    /* float: left; */
    height: 330px;
    width: 65px;
    background: #fff !important;
    /* background-color: #ffffff; */

    overflow-y: auto;
}

.force-overflow-horizontal {
    min-width: 150px !important;
}

.force-overflow {
    min-height: 450px;
}

#wrapper {
    margin: auto;
}

.cityItem {
    background-color: #Fff !important;
}

.cityItem:hover {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.cityItem:active {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.storeItem {
    background-color: #Fff !important;
}

.storeItemActive {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.storeItem:active {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.storeItem:hover {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.activeItem {
    background-color: #F8F7FA !important;
    color: #E88131 !important;
}

.white {
    background-color: #fff !important;
}

.white:hover {
    background-color: #fff !important;
    color: inherit !important
}

.colordotStyle {
    margin-bottom: -0.8125rem !important;
    margin-left: -1rem !important;
    position: relative;
}


.ant-tabs-ink-bar {
    background: rgb(255, 205, 48) !important;
}

.ant-tabs-tab-btn {
    color: black !important;
}

::-webkit-scrollbar {
    display: none;
}

@media(max-width:770px) {
    .coloum_filter {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .setMarging {
        margin-left: unset !important;
    }

    .tableSetCss {
        width: 1000px !important;
        overflow: scroll !important;
    }

    .setDownloadBtn {
        flex-direction: column;
        gap: 1rem;
    }
}

.image-zoom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-zoom {
    width: 500px;
    /* Set a fixed width for the zoomed image container */
    height: 500px;
    /* Set a fixed height for the zoomed image container */
    background-position: center;
    background-repeat: no-repeat;
    transition: background-size 0.5s ease;
}

.zoom-buttons button {
    margin: 5px;
}



.popover{
z-index: 111 !important;
--bs-popover-zindex:unset !important;
}


.modal-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;

}