.bubble{
    width: "80px";
    height: "80px";
    border-radius: "50%";
    border: "4px solid var(--primary)";
    position: "absolute";
    text-align: "center";
    line-height: "70px";
    background: "white";
    cursor: "pointer";
    box-shadow: "0px 0px 6px #B2B2B2";
}

.bubble.active{
    color: "white" !important;
    background: var(--primary) !important;
}

.arrows, .central{
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: "0px 0px 6px silver";
}

.arrows>div, .central>div {
    font-size: 12px;
    text-align: center;
    width: 40px;
    vertical-align: middle;
    margin-top: 50%;
    transform: translateY(-25%);
    position: absolute;
    border-radius: 8px;
}

.arrows::before{
    cursor: pointer;
    content: "";
    position: absolute;
    border: 6px solid transparent;
    width: 0px;
    height: 0px;
    box-shadow: "0px 0px 6px silver";
}

.arrows.active{
    background-color: var(--success) !important;
    background: var(--success) !important;
}

.arrow-up::before{
    border-bottom: 6px solid var(--primary);
    margin-top: -12px;
    margin-left: -4px;
}

.arrow-up.active::before{
    border-bottom: 6px solid var(--success) !important;
}

.arrow-up>div{
    margin-left: -18px;
    margin-top: -20px;
    background-color: var(--primary);
}

.active.arrows > div {
    background-color: var(--success) !important;
}

.arrow-right::before{
    border-left: 6px solid var(--primary);
    margin-left: 20px;
    margin-top: -4px;
}

.arrow-right.active::before{
    border-left: 6px solid var(--success) !important;
}

.arrow-right>div{
    margin-left: 26px;
    margin-top: -2px;
    background-color: var(--primary);
}

.arrow-down::before{
    border-top: 6px solid var(--primary);
    margin-top: 20px;
    margin-left: -4px;
}

.arrow-down.active::before{
    border-top: 6px solid var(--success) !important;
}

.arrow-down>div{
    margin-left: -18px;
    margin-top: 30px;
    background-color: var(--primary);
}

.arrow-left::before{
    border-right: 6px solid var(--primary);
    margin-left: -12px;
    margin-top: -4px;
}

.arrow-left.active::before{
    border-right: 6px solid var(--success) !important;
}

.arrow-left>div{
    margin-left: -46px;
    margin-top: -3px;
    background-color: var(--primary);
}

/* 
.bubble{
    width: "80px";
    height: "80px";
    border-radius: "50%";
    border: "4px solid var(--danger)";
    position: "absolute";
    text-align: "center";
    line-height: "70px";
    background: "white";
    cursor: "pointer";
    box-shadow: "0px 0px 6px #B2B2B2";
}

.bubble.active{
    color: "white" !important;
    background: "var(--danger)" !important;
}

.arrows, .central{
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: "0px 0px 6px silver";
}

.arrows>div, .central>div {
    text-align: center;
    height: 50px;
    vertical-align: middle;
    margin-top: 50%;
    transform: translateY(-25%);
}

.arrows::before{
    cursor: pointer;
    content: "";
    position: absolute;
    border: 30px solid transparent;
    width: 0px;
    height: 0px;
    box-shadow: "0px 0px 6px silver";
}

.arrow-up::before{
    border-bottom: 30px solid var(--danger);
    margin-top: -60px;
    margin-left: -5px;
}
.arrow-right::before{
    border-left: 30px solid var(--danger);
    margin-left: 50px;
    margin-top: -5px;
}
.arrow-down::before{
    border-top: 30px solid var(--danger);
    margin-top: 50px;
    margin-left: -5px;
}
.arrow-left::before{
    border-right: 30px solid var(--danger);
    margin-left: -60px;
    margin-top: -5px;
} */